import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
  CardContent,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { Skeleton } from '@mui/material';
import { useAccessLogs } from 'api/siteMetrics';
import { useSiteDetails } from 'api/site';
import { AccessLogPaginationState, AccessLogRow } from 'api/siteMetrics';
import { formatDate } from 'utils/dateFormat';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faDesktop, faTablet } from '@fortawesome/pro-regular-svg-icons';
import CountryName from '../../base/CountryName';
import PaginationControls from 'component/base/PaginationControls';
import { Filters } from './types';
import { AccessFilters } from './AccessFilters';
import { loadDefaultPerPage } from 'utils/paginate';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  tableCell: {
    verticalAlign: 'top',
  },
  firstRow: {
    verticalAlign: 'top',
    fontWeight: 'normal !important' as 'normal',
  },
  author: {
    fontWeight: 'normal',
    verticalAlign: 'top',
  },
  emptyString: {
    color: theme.palette.text.secondary,
  },
}));

function UppercaseFirstLetter(str: string = '') {
  return `${str[0]?.toUpperCase() ?? ''}${str.slice(1)}`;
}

export default function RowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

function AccessTableRow({
  accessLogItem,
  domain,
}: {
  readonly accessLogItem: AccessLogRow;
  readonly domain: string;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const row = (
    <TableRow
      className={open ? 'is-active' : undefined}
      onClick={() => setOpen(!open)}
      style={{ cursor: 'pointer' }}
    >
      <TableCell className={classes.firstRow}>
        <>
          {formatDate({
            date: accessLogItem.timestamp,
            dateStyle: 'short',
            timeStyle: 'short',
          })}
        </>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Box display="inline-block" marginRight={1.5}>
          {accessLogItem.ClientDeviceType === 'desktop' && <FontAwesomeIcon icon={faDesktop} />}
          {accessLogItem.ClientDeviceType === 'mobile' && <FontAwesomeIcon icon={faMobile} />}
          {accessLogItem.ClientDeviceType === 'tablet' && <FontAwesomeIcon icon={faTablet} />}
        </Box>
        {accessLogItem.ClientIP}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <CountryName countryCode={accessLogItem.ClientCountry.toUpperCase()} />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <LightTooltip title={`https://${domain}${accessLogItem.ClientRequestURI}`}>
          <p
            style={{
              width: 300,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >{`https://${domain}${accessLogItem.ClientRequestURI}`}</p>
        </LightTooltip>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {UppercaseFirstLetter(accessLogItem.CacheCacheStatus)}
      </TableCell>
      <TableCell className={classes.tableCell}>{accessLogItem.EdgeResponseStatus}</TableCell>
    </TableRow>
  );

  if (open) {
    return (
      <>
        {row}
        <TableRow className="is-collapse">
          <TableCell colSpan={6}>
            <Box padding={1}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <strong>{t('access_log_filters.rayId')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.RayID}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('clientRequestHTTPMethodName')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.ClientRequestMethod}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('clientRequestHTTPHost')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.ClientRequestHost}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('path')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.ClientRequestURI.split('?')[0]}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('clientRequestQuery')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>
                    {accessLogItem.ClientRequestURI.split('?')[1] ?? (
                      <i className={classes.emptyString}>{t('empty_string')}</i>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('userAgent')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.ClientRequestUserAgent}</Typography>
                </Grid>
              </Grid>
            </Box>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return row;
}

export function AccessLog({
  siteId,
  duration,
}: {
  readonly siteId: string;
  readonly duration: string;
}) {
  const { data: siteDetailsData, isFetched } = useSiteDetails({ id: siteId });
  const domain = siteDetailsData?.data.result.domain ?? '';
  const [pagination, setPagination] = useState<AccessLogPaginationState>({
    perPage: loadDefaultPerPage(),
    siteId,
    domain,
    duration,
    activePageNumber: 1,
  });
  const [filters, setFilters] = useState<{
    [key in Filters]: string | undefined;
  }>({
    cacheStatus: undefined,
    ip: undefined,
    uri: undefined,
    rayId: undefined,
    deviceType: undefined,
    statusCode: undefined,
  });

  const { data, status, refetch } = useAccessLogs(
    { ...pagination, ...filters },
    { enabled: isFetched }
  );

  useEffect(() => {
    setPagination({
      ...pagination,
      duration,
    });
  }, [duration]);

  useEffect(() => {
    refetch();
  }, [
    pagination.activePageNumber,
    pagination.perPage,
    pagination.domain,
    pagination.duration,
    filters,
  ]);

  useEffect(() => {
    setPagination(current => ({ ...current, activePageNumber: 1 }));
  }, [filters]);

  const { t } = useTranslation();
  const accessLogItems = data?.data?.result || [];

  const handlePaginationChange = (activePageNumber: number) => {
    setPagination({ ...pagination, activePageNumber });
  };

  const handlePerPage = (perPage: number) => {
    setPagination({ ...pagination, perPage, activePageNumber: 1 });
  };

  const renderTableRows = () => {
    if (status === 'pending') {
      return <RowSkeleton />;
    }

    return accessLogItems.map(accessLogItem => {
      return (
        <AccessTableRow key={accessLogItem.RayID} accessLogItem={accessLogItem} domain={domain} />
      );
    });
  };

  return (
    <>
      <AccessFilters
        filters={filters}
        setFilters={setFilters}
        totalCount={data?.data.metadata.total ?? null}
      />
      <CardContent>
        <TableContainer>
          <Table aria-label="Site Access Logs">
            <TableHead>
              <TableRow>
                <TableCell width={170}>{t('date')}</TableCell>
                <TableCell>{t('deviceOrIp')}</TableCell>
                <TableCell>{t('country')}</TableCell>
                <TableCell width={300}>{t('url')}</TableCell>
                <TableCell>{t('cache')}</TableCell>
                <TableCell width={130}>{t('status_code')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accessLogItems.length === 0 ? <NoResultsFound colSpan={6} /> : renderTableRows()}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationControls
          totalRowCount={data?.data?.metadata?.total ?? accessLogItems.length}
          perPage={pagination.perPage}
          onPageChange={handlePaginationChange}
          onPerPageChange={handlePerPage}
          perPageOptions={[10, 50, 100, 250, 500]}
        />
      </CardContent>
    </>
  );
}
