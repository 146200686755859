import { Typography, Card, CardHeader, CardContent, Grid, List, ListItem } from '@mui/material';
import { SiteDetail } from 'api/site';
import Flag from 'react-flagpack';
import { formatUsage } from 'utils/usage';
import { useTranslation } from 'react-i18next';
import { CopyButton } from 'component/base/CopyButton';
import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  readonly siteDetails?: SiteDetail;
}

export function SiteDetailsCard({ siteDetails }: Props) {
  const { t } = useTranslation();

  if (!siteDetails) {
    return null;
  }

  const location = siteDetails.location?.split(' - ') || ['', ''];

  return (
    <Card>
      <CardHeader title={<Typography variant="h2">{t('site_information')}</Typography>} />
      <CardContent>
        <List>
          <ListItem>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                {t('ftp_address')}
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <strong data-testid="ftpIpAddress">{siteDetails.ftp_ip_address}</strong>
              </Grid>
              <Grid item xs={12} md={2}>
                <CopyButton value={siteDetails?.ftp_ip_address ?? ''} />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                {t('sftp_username')}
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <strong data-testid="sftpUsername">{siteDetails.sftp_username}</strong>
              </Grid>
              <Grid item xs={12} md={2}>
                <CopyButton value={siteDetails?.sftp_username ?? ''} />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                {t('cdn_url')}
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <strong data-testid="siteRocketUrl">{siteDetails.rocket_url}</strong>
              </Grid>
              <Grid item xs={12} md={2}>
                <CopyButton value={siteDetails?.rocket_url ?? ''} />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                {t('location')}
              </Grid>
              <Grid item xs={12} sm={8}>
                <ErrorBoundary
                  fallbackRender={() => (
                    <strong data-testid="siteLocation">{location[0] ?? 'unknown'}</strong>
                  )}
                >
                  <strong data-testid="siteLocation">
                    <Flag code={location[0] ?? 'US'} size="m" />{' '}
                    {typeof location[1] === 'string' ? location[1] : siteDetails.location}
                  </strong>
                </ErrorBoundary>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                {t('disk_usage')}
              </Grid>
              <Grid item xs={12} sm={8}>
                <strong data-testid="siteDiskUsage">{formatUsage(siteDetails.disk_usage)}</strong>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
