import { useState, FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { ImportSSHKey, useImportSSHKey } from 'api/sshKeys';
import { Button } from '@mui/material';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { TextField } from 'component/base/TextField';
import { useTranslation } from 'react-i18next';

interface ImportNewKeyProps {
  readonly siteId: string;
  readonly refreshData: () => void;
}

const ImportNewKey: FC<ImportNewKeyProps> = ({ siteId, refreshData }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const methods = useForm<ImportSSHKey>({
    mode: 'all',
    defaultValues: {
      name: '',
      passphrase: '',
      key: '',
    },
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isValid, isDirty },
  } = methods;

  const { mutateAsync, isPending } = useImportSSHKey(siteId);

  const onSubmit = async (data: ImportSSHKey) => {
    try {
      await mutateAsync(data);
      setOpen(false);
      refreshData();
      reset();
    } catch (err) {
      console.log(err, 'there was an error');
    }
  };

  const form = () => {
    return (
      <FormProvider {...methods}>
        <TextField
          defaultValue=""
          label={t('key_name')}
          {...register('name')}
          name="name"
          fullWidth
          rules={{ required: String(t('field_required')) }}
        />
        <TextField
          defaultValue=""
          label={t('key_passphrase')}
          {...register('passphrase')}
          name="passphrase"
          fullWidth
        />
        <TextField
          defaultValue=""
          label={t('key')}
          {...register('key')}
          name="key"
          fullWidth
          rules={{ required: String(t('field_required')) }}
        />
      </FormProvider>
    );
  };

  return (
    <>
      <Button onClick={() => setOpen(!open)} variant="contained" color="primary">
        {t('import_new_key')}
      </Button>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ConfirmationDialog
          action="confirm"
          id="import-new-ssh-key"
          forceLoadingState={isPending}
          confirmDisabled={isPending || !isValid || !isDirty}
          title="Import Key"
          open={open}
          onClose={() => setOpen(false)}
          onConfirm={async () => {
            handleSubmit(onSubmit)();
          }}
          description={form()}
        />
      </form>
    </>
  );
};

export default ImportNewKey;
