import { useRef, useState, Dispatch, SetStateAction } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TextField,
  InputAdornment,
  Paper,
  Popper,
  Stack,
} from '@mui/material';
import { SiteDetail, SiteUser, useDeleteSiteUser } from 'api/site';
import { useParams } from 'react-router-dom';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import paginate from 'utils/paginate';
import { SearchRounded } from '@mui/icons-material';
import { AddSiteUser } from 'component/partial/AddSiteUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faEnvelope, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useUserResendSiteInvite } from 'api/auth';
import { useSnackbar } from 'component/hooks/useSnackbar';

const PREFIX = 'SiteUsers';

const classes = {
  name: `${PREFIX}Name`,
};

const Root = styled('div')({
  [`& .${classes.name}`]: {
    maxWidth: '28.125rem',
  },
});

interface Props {
  readonly siteDetails?: SiteDetail;
}
type PerPage = 10 | 50 | 100;
interface PaginationState {
  perPage: PerPage;
  activePageNumber: number;
  filter: string;
}

function SiteUserRow({
  row,
  siteId,
  setUserToDelete,
}: {
  readonly row: SiteUser;
  readonly siteId: string;
  readonly setUserToDelete: Dispatch<SetStateAction<SiteUser | null>>;
}) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openRowMenu, setOpenRowMenu] = useState<SiteUser | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const resendInvite = useUserResendSiteInvite(siteId);
  const { t } = useTranslation();

  return (
    <TableRow key={row.user_id}>
      <TableCell>
        <Typography noWrap className={classes.name}>
          {row.email}
        </Typography>
      </TableCell>
      <TableCell>{row.status}</TableCell>
      <TableCell>
        <>
          <Button
            ref={anchorRef}
            variant="text"
            color="primary"
            onClick={async e => {
              setOpenRowMenu(row);
              e.preventDefault();
              e.stopPropagation();
            }}
            fullWidth
          >
            <FontAwesomeIcon icon={faEllipsisV} size="lg" />
          </Button>
          <Popper
            open={openRowMenu !== null}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 100 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpenRowMenu(null)}>
                    <MenuList
                      key={row.user_id}
                      autoFocusItem={openRowMenu !== null}
                      id="menu-list-grow"
                      onKeyDown={() => {}}
                    >
                      {row.status === 'invited' && (
                        <MenuItem
                          onClick={async e => {
                            await resendInvite.mutateAsync(row.user_id);
                            enqueueSnackbar(
                              t('user_resend_invite_success', { userName: row.email }),
                              {
                                key: 'resendInviteSuccess',
                                variant: 'success',
                              }
                            );
                            setOpenRowMenu(null);
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <Box display="inline-block" marginRight={2}>
                            <FontAwesomeIcon icon={faEnvelope} size="sm" />
                          </Box>{' '}
                          {t('resend_email')}
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={e => {
                          setUserToDelete(openRowMenu);
                          setOpenRowMenu(null);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Box display="inline-block" marginRight={2}>
                          <FontAwesomeIcon icon={faTrash} size="sm" />
                        </Box>{' '}
                        {t('delete')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      </TableCell>
    </TableRow>
  );
}

export function SiteUserList({ siteDetails }: Props) {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { mutateAsync } = useDeleteSiteUser(siteId);
  const [pagination, setPagination] = useState<PaginationState>({
    perPage: 100,
    activePageNumber: 1,
    filter: '',
  });
  const [userToDelete, setUserToDelete] = useState<SiteUser | null>(null);
  const rows = siteDetails?.site_users ?? [];

  const { t } = useTranslation();

  const filteredUsers =
    pagination.filter.length > 0
      ? rows.filter(b => b.email.toLowerCase().indexOf(pagination.filter.toLowerCase()) !== -1)
      : rows;

  const userList = paginate(filteredUsers, pagination.perPage, pagination.activePageNumber);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({ ...pagination, activePageNumber: 1, filter: event.target.value });
  };

  const handleSubmit = async () => {
    if (!userToDelete) return null;

    await mutateAsync(userToDelete.user_id);
    setUserToDelete(null);
  };

  function renderRows() {
    if (!userList.length) {
      return (
        <TableRow>
          <TableCell colSpan={3} align="center">
            {t('users_none')}
          </TableCell>
        </TableRow>
      );
    }

    return userList.map(row => {
      return (
        <SiteUserRow
          key={row.user_id}
          row={row}
          siteId={siteId}
          setUserToDelete={setUserToDelete}
        />
      );
    });
  }

  return (
    <Root>
      {userToDelete ? (
        <ConfirmationDialog
          action="delete"
          title={t('user_delete')}
          description={t('user_delete_confirmation_description', {
            user: userToDelete.email,
          })}
          onClose={() => setUserToDelete(null)}
          onConfirm={handleSubmit}
        />
      ) : null}
      <Card>
        <CardHeader action={<AddSiteUser siteDetails={siteDetails} />} title={t('site_users')} />
        <CardContent>
          <Stack spacing={2}>
            <Typography>{t('site_users_description')}</Typography>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={handleFilter}
                variant="outlined"
                placeholder={String(t('user_search_label'))}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" disableTypography component="button">
                      <SearchRounded />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Stack>
          <TableContainer>
            <Table aria-label="Site Users Table" data-testid="siteUsersTable">
              <TableHead>
                <TableRow>
                  <TableCell>{t('email')}</TableCell>
                  <TableCell>{t('status')}</TableCell>
                  <TableCell width={104}>{t('action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderRows()}</TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Root>
  );
}
