import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ContentViewCard from 'component/base/ContentViewCard';
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Search, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { sortByKey } from 'utils/sort';
import {
  SSHKeyList,
  useDeactivateSSHKey,
  useListSSHKeys,
  useActivateSSHKey,
  useDeleteSSHKey,
  useViewSSHInfo,
} from 'api/sshKeys';
import Skeleton from 'component/view/SiteDetails/Advanced/skeletons/SSHKeys';
import SSHRow from './components/SSHRow';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import ImportNewKey from './components/ImportNewKey';
import { useTranslation } from 'react-i18next';

const PREFIX = 'SSHKeys';

const classes = {
  textField: `${PREFIX}TextField`,
  textArea: `${PREFIX}TextArea`,
};

const StyledContentViewCard = styled(ContentViewCard)({
  [`& .${classes.textField}`]: {
    '@media (min-width: 37.5rem)': {
      maxWidth: '18.75rem',
    },
  },
  [`& .${classes.textArea}`]: {
    width: '100%',
  },
});

export interface ConfirmModal {
  type: 'delete' | 'activate' | 'deactivate' | 'preview';
  key?: string;
  name: string;
}

const SSHRows = ({
  isLoading,
  sshKeys,
  handleModalPopup,
}: {
  readonly isLoading: boolean;
  readonly sshKeys: SSHKeyList;
  readonly handleModalPopup: (data: ConfirmModal) => unknown;
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Skeleton />;
  }

  return !sshKeys.length ? (
    <TableRow>
      <TableCell align="center" colSpan={3}>
        {t('no_results_found')}
      </TableCell>
    </TableRow>
  ) : (
    <>
      {sshKeys.map(({ name, key, authstatus, created }, i: number) => (
        <SSHRow
          key={`${created}-${i}`}
          name={name!}
          isAuthorized={authstatus === 'authorized'}
          onDelete={() => handleModalPopup({ name: name!, type: 'delete' })}
          onActivate={() => handleModalPopup({ name: name!, type: 'activate' })}
          onDeactivate={() => handleModalPopup({ name: name!, type: 'deactivate' })}
          onPreview={() => handleModalPopup({ name: name!, type: 'preview', key: key })}
        />
      ))}
    </>
  );
};

const SSHKeys = () => {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const [filter, setFilter] = useState('');
  const [invertList, setInvertList] = useState(false);
  const [modal, setModal] = useState<ConfirmModal | null>(null);
  const { t } = useTranslation();

  const { data, isLoading, refetch: getSSHKeys } = useListSSHKeys(siteId);

  const sortedKeyList = sortByKey(data?.data?.result ?? [], 'authstatus', invertList);
  const filteredKeyList =
    filter.length > 0
      ? sortedKeyList.filter(s => s.name?.toLowerCase().indexOf(filter) !== -1)
      : sortedKeyList;

  const { mutateAsync: deactivateKey, isPending: isDeactivating } = useDeactivateSSHKey(siteId);
  const { mutateAsync: activateKey, isPending: isActivating } = useActivateSSHKey(siteId);
  const { mutateAsync: deleteKey, isPending: isDeleting } = useDeleteSSHKey(siteId);
  const {
    data: sshInfo,
    isLoading: isFetchingInfo,
    refetch: viewSSHInfo,
  } = useViewSSHInfo(siteId, modal?.name || '');

  const previewKeyValue = sshInfo?.data?.result?.key || '';

  useEffect(() => {
    if (modal?.key) {
      viewSSHInfo();
    }
  }, [modal]);

  const handleModalPopup = (data: ConfirmModal) => {
    setModal(data);
  };

  const modalContent = () => {
    switch (modal?.type) {
      case 'activate':
      case 'deactivate':
      case 'delete':
        return {
          title: `${modal.type[0].toUpperCase() + modal.type.slice(1)} Key`,
          description: t('action_description', { type: modal.type, title: modal.name }),
        };
      default:
        return {
          title: t('view_ssh_key'),
          description: (
            <textarea
              className={classes.textArea}
              rows={12}
              disabled
              value={isFetchingInfo ? String(t('loading_data')) : previewKeyValue}
            />
          ),
        };
    }
  };

  const handleSubmit = async () => {
    if (!modal) return false;

    try {
      switch (modal.type) {
        case 'delete':
          await deleteKey(modal.name);
          break;
        case 'activate':
          await activateKey(modal.name);
          break;
        case 'deactivate':
          await deactivateKey(modal.name);
          break;
      }

      getSSHKeys();
      setModal(null);
    } catch (err) {
      console.log(err, 'the error');
    }
  };

  const modalIsLoading = isDeactivating || isActivating || isDeleting;

  return (
    <StyledContentViewCard
      title={<Typography variant="h2">{t('ssh_keys')}</Typography>}
      subheader={<Typography>{t('ssh_keys_description')}</Typography>}
    >
      <>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder={String(t('filter_keys'))}
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFilter(event.target.value)
              }
              fullWidth
              className={classes.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disableTypography component="button">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <ImportNewKey siteId={siteId} refreshData={getSSHKeys} />
          </Grid>
        </Grid>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box component="span">{t('name')}</Box>
                </TableCell>
                <TableCell>
                  <Box component="span">
                    {t('authorization_status')}
                    <Button onClick={() => setInvertList(!invertList)} color="primary">
                      {invertList ? <ArrowDropUp /> : <ArrowDropDown />}
                    </Button>
                  </Box>
                </TableCell>
                <TableCell width={172}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <SSHRows
                isLoading={isLoading}
                sshKeys={filteredKeyList}
                handleModalPopup={handleModalPopup}
              />
            </TableBody>
          </Table>
        </TableContainer>

        <ConfirmationDialog
          action={modal?.type === 'delete' ? 'delete' : 'confirm'}
          id="ssh-confirm-modal"
          forceLoadingState={modalIsLoading}
          title={modalContent().title}
          open={Boolean(modal)}
          onClose={() => setModal(null)}
          onConfirm={modal?.type === 'preview' ? undefined : handleSubmit}
          description={
            modalContent()?.description ? (
              <Typography>{modalContent().description}</Typography>
            ) : undefined
          }
        />
      </>
    </StyledContentViewCard>
  );
};

export default SSHKeys;
