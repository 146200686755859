import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiResponse } from './types';
import { AxiosService } from 'api/axiosService';
import { AxiosResponse } from 'axios';

export interface Backup {
  created: string;
  id: number;
  label: string;
  site_id: number;
  status: BackupStatus;
  task_id: string;
  download_link: string;
}

export interface BackupTask {
  id: string;
  perc: number;
  site_id: number;
  message: number;
  task_status: BackupStatus;
  task_type: string;
  updated: string;
}

export interface CreateBackupSchema {
  label: string;
  backup_directory: boolean;
  backup_database: boolean;
}

export interface RestorePostBody {
  backup_directory: boolean;
  backup_database: boolean;
  backupId: number;
}

export interface RestoreFilesPostBody {
  files?: Array<string>;
  restoreType: 'partial' | 'full';
  backupId: string;
}

export type BackupStatus =
  | 'NEW'
  | 'PROGRESS'
  | 'ERROR'
  | 'DONE'
  | 'DELETE'
  | 'RESTORE'
  | 'DOWNLOAD';

export const useGetBackups = (
  siteId: string
): UseQueryResult<AxiosResponse<ApiResponse<Backup[]>>, unknown> => {
  return useQuery({
    queryKey: [`backups-${siteId}`],
    queryFn: async () => await AxiosService.get<ApiResponse<Backup[]>>(`sites/${siteId}/backup`),
  });
};

export const usePollTask = (
  siteId: string,
  taskId: string,
  enabled: boolean
): UseQueryResult<AxiosResponse<ApiResponse<BackupTask[]>>, unknown> => {
  return useQuery({
    queryKey: [`backups-tasks-${siteId}-polling`],

    queryFn: async () =>
      await AxiosService.get<ApiResponse<BackupTask[]>>(`sites/${siteId}/tasks?task_id=${taskId}`),

    enabled: siteId === '' ? false : enabled,
    refetchInterval: 5000,
  });
};

type GetDownloadLinkData = {
  taskId: string;
};
export const useGetDownloadLink = (
  siteId: string
): UseMutationResult<
  AxiosResponse<ApiResponse<Backup[]>>,
  unknown,
  GetDownloadLinkData,
  unknown
> => {
  return useMutation({
    mutationKey: [`backups-tasks-${siteId}-link`],

    mutationFn: async (data: GetDownloadLinkData) =>
      await AxiosService.get<ApiResponse<Backup[]>>(
        `sites/${siteId}/backup?task_id=${data.taskId}`
      ),
  });
};

export const useGetBackupInProgressTasks = (
  siteId: string
): UseQueryResult<AxiosResponse<ApiResponse<BackupTask[]>>, unknown> => {
  return useQuery({
    queryKey: [`backups-in-progress-tasks-${siteId}`],

    queryFn: async () =>
      await AxiosService.get<ApiResponse<BackupTask[]>>(
        `sites/${siteId}/tasks?task_status=PROGRESS`
      ),
  });
};

export const useCreateBackup = (
  siteId: string
): UseMutationResult<AxiosResponse<any>, unknown, CreateBackupSchema, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`backups-${siteId}`],

    mutationFn: async (postData: CreateBackupSchema) =>
      await AxiosService.post(`sites/${siteId}/backup`, postData),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [`backups-${siteId}`],
      }),
  });
};

export const useDeleteBackup = (
  siteId: string
): UseMutationResult<AxiosResponse<ApiResponse<Backup[]>>, number, number, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`backups-${siteId}`],

    mutationFn: async (backupId: number) =>
      await AxiosService.delete<ApiResponse<Backup[]>>(`sites/${siteId}/backup/${backupId}`),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [`backups-${siteId}`],
      }),
  });
};

type TaskIdResponse = { task_id: string };

export const useRestoreBackup = (siteId: string) => {
  return useMutation({
    mutationKey: [`backups-${siteId}`],

    mutationFn: async (postData: RestorePostBody) =>
      await AxiosService.post<ApiResponse<TaskIdResponse>>(
        `sites/${siteId}/backup/${postData.backupId}/restore`,
        { backup_database: postData.backup_database, backup_directory: postData.backup_directory }
      ),
  });
};

export const useRestoreAutomatedBackupFiles = (siteId: string) => {
  return useMutation({
    mutationKey: [`backups-files-${siteId}`],

    mutationFn: async ({ backupId, files, restoreType }: RestoreFilesPostBody) =>
      await AxiosService.post<ApiResponse<TaskIdResponse>>(
        `sites/${siteId}/backup/automated/${backupId}/restore/files`,
        {
          files,
          restore_type: restoreType,
        }
      ),
  });
};

export const useRestoreAutomatedBackupDatabase = (siteId: string) => {
  return useMutation({
    mutationKey: [`backups-database-${siteId}`],

    mutationFn: async ({ backupId }: { backupId: string }) =>
      await AxiosService.post<ApiResponse<TaskIdResponse>>(
        `sites/${siteId}/backup/automated/${backupId}/restore/database`,
        {}
      ),
  });
};

export interface RestoreAutomatedPostBody {
  restoreId: string;
}

export const useRestoreAutomatedBackup = (siteId: string) => {
  return useMutation({
    mutationKey: [`automated-backups-${siteId}`],

    mutationFn: async ({ restoreId }: RestoreAutomatedPostBody) =>
      await AxiosService.post<ApiResponse<TaskIdResponse>>(
        `sites/${siteId}/backup/automated/${restoreId}/restore`,
        {}
      ),
  });
};

export type AutomatedBackup = {
  created_at: string;
  recoveryPoint: number;
};

export const useGetAutomatedBackups = (
  siteId: string
): UseQueryResult<AxiosResponse<ApiResponse<AutomatedBackup[]>>, unknown> => {
  return useQuery({
    queryKey: [`automated-backups-${siteId}`],

    queryFn: async () =>
      await AxiosService.get<ApiResponse<AutomatedBackup[]>>(`sites/${siteId}/backup/automated`),
  });
};
