import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Card, CardHeader, CardContent, MenuItem, TextField, Typography } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { useSiteBandwidthUsage } from 'api/siteMetrics';
import { useParams } from 'react-router-dom';
import { LegendDot } from 'component/partial/Reporting/LegendDot';
import ReportingNoData from 'component/svg/chart/no-data.svg?react';
import LoadingContainer from '../LoadingContainer';
import { useTranslation } from 'react-i18next';
import { getTickFormatForDuration } from 'utils/reporting';

import { loadingContainer } from 'theme/custom/loading-container';
import { noData } from 'theme/custom/no-data';
import { tooltip } from 'theme/custom/tooltip';
import { formatUsage } from 'utils/usage';

const PREFIX = 'SiteActivityCard';

const classes = {
  name: `${PREFIX}Name`,
  loadingContainer: `${PREFIX}LoadingContainer`,
  noData: `${PREFIX}NoData`,
  tooltip: `${PREFIX}Tooltip`,
  legend: `${PREFIX}Legend`,
};

const StyledCard = styled(Card)({
  [`& .${classes.name}`]: {
    maxWidth: '18.75rem',
  },
  [`& .${classes.loadingContainer}`]: {
    ...loadingContainer,
    display: 'flex',
    height: '21.875rem',
  },
  [`& .${classes.noData}`]: {
    ...noData,
  },
  [`& .${classes.tooltip}`]: {
    ...tooltip,
  },
  [`& .${classes.legend}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export function SiteActivityCard() {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const [duration, setDuration] = React.useState<string>('24h');
  const { isLoading, data, refetch, isFetched } = useSiteBandwidthUsage({
    id: siteId,
    duration,
  });
  const { t } = useTranslation();
  const timeframes = [
    {
      label: t('last_30_minutes'),
      value: '30m',
    },
    {
      label: t('last_6_hours'),
      value: '6h',
    },
    {
      label: t('last_12_hours'),
      value: '12h',
    },
    {
      label: t('last_24_hours'),
      value: '24h',
    },
    {
      label: t('last_72_hours'),
      value: '72h',
    },
    {
      label: t('last_7_days'),
      value: '7d',
    },
    {
      label: t('last_30_days'),
      value: '30d',
    },
  ];

  React.useEffect(() => {
    if (isFetched) {
      refetch();
    }
  }, [duration, isFetched, refetch]);

  const lineData =
    data?.data?.data?.viewer.zones[0].series
      .map(datum => {
        return {
          y: datum.sum.edgeResponseBytes,
          x: datum.dimensions.ts,
        };
      })
      .sort((a, b) => new Date(b.x).getTime() - new Date(a.x).getTime()) ?? [];
  const totalData = lineData.reduce<number>((prev, curr) => prev + curr.y, 0);
  const hasData = !(!lineData || lineData.length === 0);

  const renderChart = () => {
    if (hasData) {
      return (
        <ResponsiveLine
          data={[
            {
              id: 'Data Transfer',
              data: lineData,
            },
          ]}
          colors={['#095bb4']}
          animate={false}
          margin={{ top: 30, right: 30, bottom: 30, left: 70 }}
          xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: true }}
          yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
          yFormat={val => formatUsage(Number(val) / 1000)}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: getTickFormatForDuration(duration),
            tickSize: 0,
            tickPadding: 5,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: val => formatUsage(val / 1000),
          }}
          enableGridX={false}
          curve={'linear'}
          enablePoints={false}
          useMesh
          enableSlices="x"
          xFormat="time:%Y-%m-%d"
          sliceTooltip={({ slice }) => {
            return (
              <Box sx={tooltip}>
                {slice.points.map(point => (
                  <Box key={point.id}>
                    <Typography variant="caption">{point.data.xFormatted}</Typography>
                    <Box>
                      <strong>{point.data.yFormatted}</strong> {point.serieId}
                    </Box>
                  </Box>
                ))}
              </Box>
            );
          }}
          {...(lineData.length === 1
            ? {
                enablePoints: true,
                pointSize: 10,
                enableGridX: true,
              }
            : null)}
        />
      );
    }
    return (
      <Box className={classes.noData}>
        <ReportingNoData />
        <Typography variant="h3">{t('no_data_to_display')}</Typography>
        <Typography>{t('no_data_to_display_description')}</Typography>
      </Box>
    );
  };

  return (
    <StyledCard>
      <CardHeader
        action={
          <TextField
            id="select-timeframe"
            select
            value={duration}
            variant="outlined"
            onChange={e => {
              setDuration(e.target.value);
            }}
          >
            {timeframes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        }
        title="Site Activity"
      />
      <CardContent>
        {isLoading || !hasData ? null : (
          <Box textAlign="center">
            <LegendDot color="rgb(31, 119, 180)" />
            {t('total_bandwidth')}
            <Typography variant="h1" component="div" data-testid="totalRequestsLabel">
              {formatUsage(totalData ? totalData / 1000 : 0)}
            </Typography>
          </Box>
        )}
        <Box className={classes.loadingContainer}>
          {isLoading ? <LoadingContainer /> : renderChart()}
        </Box>
      </CardContent>
    </StyledCard>
  );
}
