import React from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { InviteUserData, useUserSiteInvite } from 'api/auth';
import { useParams } from 'react-router-dom';
import { TextField } from 'component/base/TextField';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { Trans, useTranslation } from 'react-i18next';
import { SiteDetail } from 'api/site';

export function AddSiteUser({ siteDetails }: { readonly siteDetails?: SiteDetail }) {
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false);
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { mutateAsync, isPending } = useUserSiteInvite(siteId);
  const methods = useForm<InviteUserData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });
  const { handleSubmit, reset } = methods;
  const { t } = useTranslation();
  const onSubmit = async (data: InviteUserData) => {
    await mutateAsync({
      ...data,
    });
    reset();
    setShowAddDialog(false);
  };

  function handleClose() {
    reset();
    setShowAddDialog(false);
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setShowAddDialog(true);
        }}
      >
        {t('add_site_user')}
      </Button>
      <ConfirmationDialog
        action="confirm"
        description={
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2} direction="column">
                <Typography variant="body2">
                  <Trans
                    i18nKey="add_site_user_description"
                    values={{ siteName: siteDetails?.domain ?? '' }}
                  />
                </Typography>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <TextField
                      defaultValue=""
                      name="firstName"
                      disabled={isPending}
                      fullWidth
                      label={t('first_name')}
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      defaultValue=""
                      name="lastName"
                      disabled={isPending}
                      fullWidth
                      label={t('last_name')}
                      rules={{ required: true }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  defaultValue=""
                  name="email"
                  disabled={isPending}
                  fullWidth
                  label={t('email')}
                  rules={{ required: true }}
                />
              </Stack>
            </form>
          </FormProvider>
        }
        id="addUserModal"
        onConfirm={handleSubmit(onSubmit)}
        onClose={handleClose}
        open={showAddDialog}
        title={t('add_site_user')}
        confirmText={String(t('add_user'))}
        maxWidth="sm"
      />
    </>
  );
}
