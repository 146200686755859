import { FC, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { getTickFormatForDuration } from 'utils/reporting';
import { FirewallSeries } from 'api/siteMetrics';
import { formatNumber } from 'utils/number';
import { LegendDot } from '../../LegendDot';
import { sortSeriesByDate } from 'utils/sort';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { loadingContainer } from 'theme/custom/loading-container';
import { tooltip } from 'theme/custom/tooltip';
import { linearGradientDef } from '@nivo/core';
const PREFIX = 'EventChart';

const classes = {
  loadingContainer: `${PREFIX}LoadingContainer`,
  tooltip: `${PREFIX}Tooltip`,
};

const Root = styled('div')({
  [`& .${classes.loadingContainer}`]: {
    ...loadingContainer,
    display: 'flex',
    height: '21.875rem',
    '&:not(:first-of-type)': {
      marginTop: '0 !important',
    },
  },
  [`& .${classes.tooltip}`]: {
    ...tooltip,
  },
});

interface EventChartProps {
  readonly siteId: string;
  readonly duration: string;
  readonly query: UseQueryResult<AxiosResponse<any>, unknown>;
}

const BLOCK_COLOR = '#77a827';
const CHALLENGE_COLOR = '#ff9437';
const ALLOW_COLOR = '#0083FF';

interface DateEntires {
  y: number;
  x: string;
}

const EventChart: FC<EventChartProps> = ({ siteId, duration, query }) => {
  const { data, refetch, isFetched } = query;
  const series = data?.data?.data?.viewer?.zones[0]?.series ?? [];
  const { t } = useTranslation();

  const filterByMetric = (metric: 'block' | 'jschallenge' | 'allow'): FirewallSeries[] => {
    return series.length > 0
      ? series.filter((d: FirewallSeries) => d?.dimensions?.metric === metric)
      : [];
  };
  const blockedData = filterByMetric('block');
  const challengeData = filterByMetric('jschallenge');
  const allowData = filterByMetric('allow');

  const totalEvents =
    challengeData.length + (data?.data?.blockTotal ?? 0) + (data?.data?.allowTotal ?? 0);

  const graphData = (arr: Array<FirewallSeries>) => {
    return arr.reduce((acc: DateEntires[], curr: FirewallSeries, i: number) => {
      return [
        ...acc,
        {
          y: curr.count,
          x: curr.dimensions.ts,
        },
      ];
    }, []);
  };

  useEffect(() => {
    if (isFetched) {
      refetch();
    }
  }, [duration, isFetched]);

  const formatData = (date: string) => {
    const [day] = date.split(' ');
    const newDate = new Date(day);
    const options = {
      month: 'short',
      day: 'numeric',
    };
    const formatted = new Intl.DateTimeFormat('en-US', options as object).format(newDate);
    return formatted;
  };

  let blockedGraphData = graphData(blockedData);
  let challengeGraphData = graphData(challengeData);
  let allowGraphData = graphData(allowData);

  // zero-fill missing datums in all arrays
  blockedGraphData.forEach(datum => {
    if (!challengeGraphData.some(d => d.x === datum.x)) {
      challengeGraphData.push({
        x: datum.x,
        y: 0,
      });
    }

    if (!allowGraphData.some(d => d.x === datum.x)) {
      allowGraphData.push({
        x: datum.x,
        y: 0,
      });
    }
  });

  challengeGraphData.forEach(datum => {
    if (!blockedGraphData.some(d => d.x === datum.x)) {
      blockedGraphData.push({
        x: datum.x,
        y: 0,
      });
    }

    if (!allowGraphData.some(d => d.x === datum.x)) {
      allowGraphData.push({
        x: datum.x,
        y: 0,
      });
    }
  });

  allowGraphData.forEach(datum => {
    if (!blockedGraphData.some(d => d.x === datum.x)) {
      blockedGraphData.push({
        x: datum.x,
        y: 0,
      });
    }

    if (!challengeGraphData.some(d => d.x === datum.x)) {
      challengeGraphData.push({
        x: datum.x,
        y: 0,
      });
    }
  });

  // sort data
  blockedGraphData = blockedGraphData.sort(sortSeriesByDate);
  challengeGraphData = challengeGraphData.sort(sortSeriesByDate);
  allowGraphData = allowGraphData.sort(sortSeriesByDate);

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Box textAlign="center">
            {t('event_graph_total')}
            <Typography variant="h1" component="div">
              {formatNumber(totalEvents, 1)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box textAlign="center">
            <LegendDot color={BLOCK_COLOR} />
            {t('actvitity_type_block')}
            <Typography variant="h1" component="div">
              {formatNumber(data?.data?.blockTotal ?? 0, 1)}
            </Typography>
            {data?.data?.blockTotal ? (
              <Typography variant="body2">
                {formatNumber((data?.data?.blockTotal / totalEvents) * 100, 1)} {'%'}
              </Typography>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box textAlign="center">
            <LegendDot color={ALLOW_COLOR} />
            {t('actvitity_type_allow')}
            <Typography variant="h1" component="div">
              {formatNumber(data?.data?.allowTotal ?? 0, 1)}
            </Typography>
            {data?.data?.allowTotal ? (
              <Typography variant="body2">
                {formatNumber((data?.data?.allowTotal / totalEvents) * 100, 1)} {'%'}
              </Typography>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box textAlign="center">
            <LegendDot color={CHALLENGE_COLOR} />
            {t('js_challenge')}
            <Typography variant="h1" component="div">
              {formatNumber(challengeData.length, 1)}
            </Typography>
            {data?.data?.challengeTotal ? (
              <Typography variant="body2">
                {formatNumber((data?.data?.challengeTotal / totalEvents) * 100, 1)} {'%'}
              </Typography>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.loadingContainer}>
        <ResponsiveLine
          colors={[CHALLENGE_COLOR, ALLOW_COLOR, BLOCK_COLOR]}
          data={[
            {
              id: t('actvitity_type_jschallenge') as string,
              data: challengeGraphData,
            },
            {
              id: t('actvitity_type_allow') as string,
              data: allowGraphData,
            },
            {
              id: t('actvitity_type_block') as string,
              data: blockedGraphData,
            },
          ]}
          animate
          enableArea
          defs={[
            linearGradientDef('gradientA', [
              { offset: 0, color: 'inherit' },
              { offset: 100, color: 'inherit', opacity: 20 },
            ]),
          ]}
          margin={{ top: 30, right: 30, bottom: 30, left: 50 }}
          xScale={{ type: 'time', format: '%Y-%m-%dT%H:%M:%SZ', useUTC: true }}
          yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: getTickFormatForDuration(duration),
            tickSize: 0,
            tickPadding: 5,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: val => formatNumber(val, 1),
          }}
          enableGridX={false}
          curve={'monotoneY'}
          enablePoints={false}
          useMesh
          enableSlices="x"
          xFormat="time:%Y-%m-%d %I:%m%p"
          sliceTooltip={({ slice }) => (
            <Box className={classes.tooltip}>
              <Typography variant="caption">
                {formatData((slice?.points[0]?.data?.xFormatted as string) ?? '')}
              </Typography>
              {slice.points.map(point => {
                return (
                  <Box key={point.id}>
                    <strong>{String(point.data.y)}</strong> {point.serieId}
                  </Box>
                );
              })}
            </Box>
          )}
          {...(challengeGraphData.length === 1 &&
          allowGraphData.length === 1 &&
          blockedGraphData.length === 1
            ? {
                enablePoints: true,
                pointSize: 10,
                enableGridX: true,
              }
            : null)}
        />
      </Box>
    </Root>
  );
};

export default EventChart;
