import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import {
  useSiteCdnEdgeOrigin,
  useSiteCacheStatus,
  useSiteCacheContent,
  useSiteCacheTop,
  SiteCacheTopDatum,
  SiteCdnEdgeOrigin,
} from 'api/siteMetrics';
import { sortSeriesByDate } from 'utils/sort';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Typography,
  TableBody,
  TableRow,
  Tooltip,
} from '@mui/material';
import { formatNumber } from 'utils/number';
import { getTickFormatForDuration } from 'utils/reporting';
import { LegendDot } from './LegendDot';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import ReportingNoData from 'component/svg/chart/no-data.svg?react';
import CountryName from '../../base/CountryName';

import { loadingContainer } from 'theme/custom/loading-container';
import { noData } from 'theme/custom/no-data';
import { tooltip } from 'theme/custom/tooltip';
import { CircularProgress } from 'component/base/CircularProgress';
import { useTranslation } from 'react-i18next';

const PREFIX = 'CDNReporting';

const classes = {
  name: `${PREFIX}Name`,
  loadingContainer: `${PREFIX}LoadingContainer`,
  loadingContainerProgress: `${PREFIX}LoadingContainerProgress`,
  bar: `${PREFIX}Bar`,
  barContainer: `${PREFIX}BarContainer`,
  noData: `${PREFIX}NoData`,
  tooltip: `${PREFIX}Tooltip`,
  tableCell: `${PREFIX}TableCell`,
  typography: `${PREFIX}Typography`,
};

const Root = styled('div')({
  [`& .${classes.name}`]: {
    maxWidth: '18.75rem',
  },
  [`& .${classes.loadingContainer}`]: {
    ...loadingContainer,
    display: 'flex',
    height: '21.875rem',
    '&:not(:first-of-type)': {
      marginTop: '0 !important',
    },
  },
  [`& .${classes.loadingContainerProgress}`]: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  [`& .${classes.bar}`]: {
    '& [class*="h1"]': {
      fontSize: '1.625rem',
    },
  },
  [`& .${classes.barContainer}`]: {
    height: '3.75rem',
    '&:not(:first-of-type)': {
      marginTop: '1.25rem',
    },
  },
  [`& .${classes.noData}`]: {
    ...noData,
  },
  [`& .${classes.tooltip}`]: {
    ...tooltip,
  },
  [`& .${classes.typography}`]: {
    borderBottom: '0.0625rem solid var(--color-hint-of-red)',
    marginBottom: '1.25rem',
    marginTop: '2.5rem',
    paddingBottom: '0.5rem',
  },
});

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.tableCell}`]: {
    '& > span': {
      alignItems: 'center',
      textTransform: 'none',
      '& > span': {
        fontSize: '0.875rem',
        fontWeight: 400,
        marginLeft: '0.3125rem',
        opacity: '0.5',
      },
    },
  },
});

interface Props {
  readonly siteId: string;
  readonly duration: string;
}

const SERVED_BY_EDGE_COLOR = '#77a827';
const SERVED_BY_ORIGIN_COLOR = '#ff9437';

type TotalInformationProps = {
  readonly color: string;
  readonly title: string;
  readonly total: number;
  readonly seriesTotal: number;
};

function TotalInformation({ color, title, total, seriesTotal }: TotalInformationProps) {
  return (
    <Box textAlign="center">
      <LegendDot color={color} />
      {title}
      <Typography variant="h1" component="div">
        {formatNumber(total, 1)}
      </Typography>
      <Typography variant="body2">
        {formatNumber((total / seriesTotal) * 100, 1)} {'%'}
      </Typography>
    </Box>
  );
}

const EdgeOriginReport = ({
  duration,
  query,
}: {
  readonly duration: string;
  readonly query: UseQueryResult<AxiosResponse<SiteCdnEdgeOrigin>, unknown>;
}) => {
  const { data: edgeOriginData } = query;

  const { t } = useTranslation();

  let servedByEdgeData =
    edgeOriginData?.data.data.viewer.zones[0].servedByEdge.map(datum => {
      return {
        y: datum.count,
        x: datum.dimensions.ts,
      };
    }) ?? [];

  let servedByOriginData =
    edgeOriginData?.data.data.viewer.zones[0].servedByOrigin.map(datum => {
      return {
        y: datum.count,
        x: datum.dimensions.ts,
      };
    }) ?? [];

  // zero-fill missing datums in both arrays
  servedByEdgeData.forEach(datum => {
    if (!servedByOriginData.some(d => d.x === datum.x)) {
      servedByOriginData.push({
        x: datum.x,
        y: 0,
      });
    }
  });

  servedByOriginData.forEach(datum => {
    if (!servedByEdgeData.some(d => d.x === datum.x)) {
      servedByEdgeData.push({
        x: datum.x,
        y: 0,
      });
    }
  });

  // sort data
  servedByEdgeData = servedByEdgeData.sort(sortSeriesByDate);
  servedByOriginData = servedByOriginData.sort(sortSeriesByDate);

  const totalEdgeServed = edgeOriginData?.data.serveByEdgeTotal ?? 0;
  const totalOriginServed = edgeOriginData?.data.serveByOriginTotal ?? 0;
  const totalServed = totalEdgeServed + totalOriginServed;
  const mostDataPoints = Math.max(servedByOriginData.length, servedByEdgeData.length);

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box textAlign="center">
            {t('total')}
            <Typography variant="h1" component="div">
              {formatNumber(totalServed, 1)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TotalInformation
            color={SERVED_BY_EDGE_COLOR}
            title={t('served_by_edge')}
            total={totalEdgeServed}
            seriesTotal={totalServed}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TotalInformation
            color={SERVED_BY_ORIGIN_COLOR}
            title={t('served_by_origin')}
            total={totalOriginServed}
            seriesTotal={totalServed}
          />
        </Grid>
      </Grid>
      <Box className={classes.loadingContainer}>
        <ResponsiveLine
          data={[
            {
              id: String(t('served_by_origin')),
              data: servedByOriginData,
            },
            {
              id: String(t('served_by_edge')),
              data: servedByEdgeData,
            },
          ]}
          colors={[SERVED_BY_ORIGIN_COLOR, SERVED_BY_EDGE_COLOR]}
          animate
          enableArea
          defs={[
            linearGradientDef('gradientA', [
              { offset: 0, color: 'inherit' },
              { offset: 100, color: 'inherit', opacity: 20 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
          margin={{ top: 30, right: 30, bottom: 30, left: 50 }}
          xScale={{ type: 'time', format: '%Y-%m-%dT%H:%M:%SZ', useUTC: true }}
          yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: getTickFormatForDuration(duration),
            tickSize: 0,
            tickPadding: 5,
            truncateTickAt: 5,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: val => formatNumber(val, 1),
          }}
          enableGridX={false}
          curve={'monotoneY'}
          enablePoints={false}
          useMesh
          enableSlices="x"
          xFormat="time:%Y-%m-%d %I:%m%p"
          sliceTooltip={({ slice }) => {
            return (
              <Box className={classes.tooltip}>
                <Typography variant="caption">{slice.points[0].data.xFormatted ?? ''}</Typography>
                {slice.points.map(point => (
                  <Box key={point.id}>
                    <strong>{String(point.data.y)}</strong> {point.serieId}
                  </Box>
                ))}
              </Box>
            );
          }}
          {...(servedByEdgeData.length === 1 && servedByOriginData.length === 1
            ? {
                enablePoints: true,
                pointSize: 10,
                enableGridX: true,
              }
            : null)}
        />
      </Box>
    </Root>
  );
};

const cacheStatusColors = {
  hit: '#9ec285',
  none: '#617f89',
  dynamic: '#dbb440',
  miss: '#e38585',
  expired: '#eba35b',
  bypass: '#18a8c8',
  revalidated: '#9D94EC',
};

const CacheStatusReport = ({ duration, siteId }: Props) => {
  const {
    data: cacheStatusData,
    refetch,
    isFetched,
  } = useSiteCacheStatus({
    id: siteId,
    duration,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (isFetched) {
      refetch();
    }
  }, [duration, isFetched]);

  let total = 0;

  const series =
    cacheStatusData?.data.data.viewer.zones[0].cacheStatus.reduce<{
      [key: string]: string | number;
    }>(
      (prev, datum) => {
        total += datum.count;
        return {
          ...prev,
          [datum.dimensions.cacheStatus]: datum.count ?? 0,
        };
      },
      { metrics: 'all' }
    ) ?? {};

  return (
    <Root>
      <Box className={classes.bar}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.typography}>
              {t('cache_status')}
            </Typography>
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <TotalInformation
              color={cacheStatusColors.hit}
              title={t('hit')}
              total={Number(series.hit ?? 0)}
              seriesTotal={total}
            />
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <TotalInformation
              color={cacheStatusColors.miss}
              title={t('miss')}
              total={Number(series.miss ?? 0)}
              seriesTotal={total}
            />
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <TotalInformation
              color={cacheStatusColors.none}
              title={t('none')}
              total={Number(series.none ?? 0)}
              seriesTotal={total}
            />
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <TotalInformation
              color={cacheStatusColors.dynamic}
              title={t('dynamic')}
              total={Number(series.dynamic ?? 0)}
              seriesTotal={total}
            />
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <TotalInformation
              color={cacheStatusColors.expired}
              title={t('expired')}
              total={Number(series.expired ?? 0)}
              seriesTotal={total}
            />
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <TotalInformation
              color={cacheStatusColors.bypass}
              title={t('bypass')}
              total={Number(series.bypass ?? 0)}
              seriesTotal={total}
            />
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <TotalInformation
              color={cacheStatusColors.revalidated}
              title={t('revalidated')}
              total={Number(series.revalidated ?? 0)}
              seriesTotal={total}
            />
          </Grid>
        </Grid>
        <Box className={classes.barContainer}>
          <ResponsiveBar
            data={[series]}
            keys={['hit', 'miss', 'none', 'dynamic', 'expired', 'bypass', 'revalidated']}
            colors={[
              cacheStatusColors.hit,
              cacheStatusColors.miss,
              cacheStatusColors.none,
              cacheStatusColors.dynamic,
              cacheStatusColors.expired,
              cacheStatusColors.bypass,
              cacheStatusColors.revalidated,
            ]}
            indexBy="metrics"
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            layout="horizontal"
            valueScale={{
              type: 'linear',
              max: total,
            }}
            indexScale={{ type: 'band', round: true }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 0,
              tickRotation: 0,
              format: () => '',
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              format: () => '',
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            tooltip={({ id, value }) => (
              <Box className={classes.tooltip}>
                <strong>{value}</strong> {'('}
                {((value / total) * 100).toFixed(1)}
                {'%) '}
                {id}
              </Box>
            )}
          />
        </Box>
      </Box>
    </Root>
  );
};

function CacheContentReport({ duration, siteId }: Props) {
  const {
    data: cacheContentData,
    refetch,
    isFetched,
  } = useSiteCacheContent({
    id: siteId,
    duration,
  });

  useEffect(() => {
    if (isFetched) {
      refetch();
    }
  }, [duration, isFetched]);

  const byContentType =
    cacheContentData?.data.data.viewer.zones[0].cacheStatusByContentType.reduce<{
      [key: string]: Array<{ count: number; cacheStatus: string }> | undefined;
    }>((prev, curr) => {
      const byContentType = prev[curr.dimensions.edgeResponseContentTypeName];
      if (Array.isArray(byContentType)) {
        byContentType.push({
          count: curr.count,
          cacheStatus: curr.dimensions.cacheStatus,
        });
      } else {
        prev[curr.dimensions.edgeResponseContentTypeName] = [
          {
            count: curr.count,
            cacheStatus: curr.dimensions.cacheStatus,
          },
        ];
      }

      return prev;
    }, {}) ?? {};

  const series = Object.keys(byContentType).map(key => {
    const cacheStatues = byContentType[key] ?? [];
    return {
      contentType: key,
      ...(Array.isArray(byContentType[key])
        ? cacheStatues.reduce<{ [key: string]: number }>((prev, curr) => {
            return {
              ...prev,
              [curr.cacheStatus]: curr.count,
            };
          }, {})
        : {}),
    };
  });

  return (
    <Box className={classes.loadingContainer}>
      <ResponsiveBar
        data={series}
        keys={['hit', 'miss', 'none', 'dynamic', 'expired', 'bypass']}
        colors={[
          cacheStatusColors.hit,
          cacheStatusColors.miss,
          cacheStatusColors.none,
          cacheStatusColors.dynamic,
          cacheStatusColors.expired,
          cacheStatusColors.bypass,
        ]}
        indexBy="contentType"
        margin={{ top: 30, right: 30, bottom: 30, left: 50 }}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="white"
        tooltip={({ id, value }) => (
          <Box className={classes.tooltip}>
            <strong>{value}</strong> {id}
          </Box>
        )}
      />
    </Box>
  );
}

interface CacheTopTableProps {
  readonly data: Array<SiteCacheTopDatum>;
  readonly title: string;
  readonly total: number;
  readonly formatKey?: (key: string) => React.ReactNode;
}

function CacheTopTable({ data, title, total, formatKey }: CacheTopTableProps) {
  const [showAll, setShowAll] = useState<boolean>(false);
  const { t } = useTranslation();

  function renderRows() {
    const rows: React.ReactNode[] = [];
    for (let i = 0; i < (!showAll ? Math.min(6, data.length) : data.length); i++) {
      const datum = data[i];
      rows.push(
        <TableRow key={datum.dimensions.metric}>
          <TableCell
            sx={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Tooltip title={datum.dimensions.metric}>
              <Typography noWrap className={classes.name}>
                {formatKey ? formatKey(datum.dimensions.metric) : datum.dimensions.metric}
              </Typography>
            </Tooltip>
          </TableCell>
          <TableCell align="right">{formatNumber(datum.count, 2)}</TableCell>
          <TableCell>
            <LinearProgress variant="determinate" value={(datum.count / total) * 100} />
          </TableCell>
        </TableRow>
      );
    }

    return rows;
  }

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <Tooltip title={title}>
                <Typography variant="h2" component="span">
                  {title} <Box component="span"> | {data.length}</Box>
                </Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="right" />
            <TableCell align="right" width={116}>
              {data.length > 5 ? (
                <Button color="primary" onClick={() => setShowAll(!showAll)}>
                  {showAll ? t('show_top') : t('show_all')}
                </Button>
              ) : null}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{data.length ? renderRows() : null}</TableBody>
      </Table>
    </StyledTableContainer>
  );
}

function CacheTopReport({ duration, siteId }: Props) {
  const {
    data: siteCacheTopData,
    refetch,
    isFetched,
  } = useSiteCacheTop({
    id: siteId,
    duration,
  });

  useEffect(() => {
    if (isFetched) {
      refetch();
    }
  }, [duration, isFetched]);
  const { t } = useTranslation();

  const data = siteCacheTopData?.data.data.viewer.zones[0];
  const total = data?.total[0]?.count ?? 0;

  return (
    <Box marginTop={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <CacheTopTable
            data={data?.topContentTypes ?? []}
            title={t('content_types')}
            total={total}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CacheTopTable data={data?.topUriPaths ?? []} title={t('paths')} total={total} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CacheTopTable
            data={data?.topCountries ?? []}
            title="Countries"
            total={total}
            formatKey={key => <CountryName countryCode={key} />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CacheTopTable
            data={data?.topStatusCodes ?? []}
            title={t('status_codes')}
            total={total}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CacheTopTable
            data={data?.topDeviceTypes ?? []}
            title={t('device_types')}
            total={total}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export function CDNReporting(props: Props) {
  const { duration, siteId } = props;
  const siteCdnEdgeOrigin = useSiteCdnEdgeOrigin(siteId, duration);
  const { isFetched } = siteCdnEdgeOrigin;
  const { t } = useTranslation();

  // if first request has no data assuming the rest of the reports have no data
  const data = siteCdnEdgeOrigin.data?.data?.data?.viewer?.zones[0]?.servedByEdge;

  useEffect(() => {
    if (isFetched) {
      siteCdnEdgeOrigin.refetch();
    }
  }, [duration, isFetched]);

  if (siteCdnEdgeOrigin.isLoading) {
    return (
      <Root>
        <Box className={classes.loadingContainer}>
          <Box className={classes.loadingContainerProgress}>
            <CircularProgress />
          </Box>
        </Box>
      </Root>
    );
  }

  if ((data === undefined || data.length === 0) && !siteCdnEdgeOrigin.isLoading) {
    return (
      <Root>
        <Box className={classes.loadingContainer}>
          <Box className={classes.noData}>
            <ReportingNoData />
            <Typography variant="h3">{t('no_data_to_display')}</Typography>
            <Typography>{t('no_data_to_display_description')}</Typography>
          </Box>
        </Box>
      </Root>
    );
  }

  return (
    <>
      <EdgeOriginReport {...props} query={siteCdnEdgeOrigin} />
      <CacheStatusReport {...props} />
      <CacheContentReport {...props} />
      <CacheTopReport {...props} />
    </>
  );
}
