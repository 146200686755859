import { FC } from 'react';
import { Backup } from 'api/backups';
import InstalledTableRow from '../components/InstalledTableRow';
import { ConfirmationModal } from '..';

interface BackupListProps {
  readonly list?: Backup[];
  readonly siteId: string;
  readonly modalData: ConfirmationModal;
  readonly refetch: () => void;
  readonly setConfirmationModal: (data: ConfirmationModal) => void;
}

const BackupList: FC<BackupListProps> = ({
  refetch,
  list,
  setConfirmationModal,
  siteId,
  modalData,
}) => {
  if (!list) {
    return null;
  }

  return (
    <>
      {list.map((b: Backup) => (
        <InstalledTableRow
          openConfirmationModal={(
            backupId: number,
            type?: 'delete' | 'restore',
            onSubmit?: () => Promise<unknown>
          ) =>
            setConfirmationModal({
              ...modalData,
              open: true,
              backupId,
              ...(type && { type }),
              ...(onSubmit && { onSubmit }),
            })
          }
          key={`${b.id}-${b.created}`}
          refreshList={refetch}
          siteId={siteId}
          {...b}
        />
      ))}
    </>
  );
};

export default BackupList;
