import { FC, useState } from 'react';
import { Box, FormControlLabel, Typography, TextField, Grid, Checkbox } from '@mui/material';
import { CreateBackupSchema } from 'api/backups';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { useCheckboxStyles } from 'component/styles/checkbox';

interface CreateBackupProps {
  readonly open: boolean;
  readonly isLoading: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (data: CreateBackupSchema) => Promise<unknown>;
}

const CreateBackup: FC<CreateBackupProps> = ({ open, onClose, onSubmit, isLoading }) => {
  const [backupFiles, setBackupFiles] = useState<boolean>(false);
  const [backupDb, setBackupDb] = useState<boolean>(false);
  const [label, setLabel] = useState<string | null>(null);
  const { t } = useTranslation();
  const checkboxClasses = useCheckboxStyles();
  const handleSubmit = async () => {
    onSubmit({
      backup_directory: backupFiles,
      backup_database: backupDb,
      label: label as string,
    });
  };

  const handleClose = () => {
    setBackupFiles(false);
    setBackupDb(false);
    onClose();
  };

  const formIsValid = (backupFiles === true || backupDb === true) && label;

  const modalBody = () => (
    <>
      <Typography>{t('backup_new_description')}</Typography>

      <Box marginTop={3} marginBottom={3}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder={String(t('backup_create_label'))}
          name="label"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLabel(event.target.value)}
        />
      </Box>

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControlLabel
              value="backupFiles"
              onChange={() => setBackupFiles(!backupFiles)}
              checked={backupFiles}
              control={<Checkbox color="primary" className={checkboxClasses.radio} />}
              label={
                <Typography className={checkboxClasses.typographyTitle}>
                  {t('backup_create_files')}
                </Typography>
              }
              className={checkboxClasses.formControlLabel}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              value="backupDb"
              onChange={() => setBackupDb(!backupDb)}
              checked={backupDb}
              control={<Checkbox color="primary" className={checkboxClasses.radio} />}
              label={
                <Typography className={checkboxClasses.typographyTitle}>
                  {t('backup_create_database')}
                </Typography>
              }
              className={checkboxClasses.formControlLabel}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <ConfirmationDialog
      forceLoadingState={isLoading}
      onConfirm={handleSubmit}
      confirmDisabled={!formIsValid}
      action="confirm"
      onClose={handleClose}
      open={open}
      description={modalBody()}
      title={t('backup_create_header')}
    />
  );
};

export default CreateBackup;
