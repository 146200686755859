import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Checkbox,
  Grid,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { SiteDetail } from 'api/site';
import { WizardStep } from 'component/base/WizardStep';
import { useForm, FormProvider } from 'react-hook-form';
import isFQDN from 'validator/lib/isFQDN';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import {
  useSetMainDomain,
  useUpdateSSLValidation,
  VerificationRecord,
  useForceValidationRecheck,
  useUpdateGoLiveMode,
} from 'api/domain';
import ReadyToGoLiveIcon from 'component/svg/ready-to-go-live/badge.svg?react';
import { CopyButton } from 'component/base/CopyButton';
import { TextField } from 'component/base/TextField';
import { Trans, useTranslation } from 'react-i18next';
import { cssVar } from 'utils/css';
import { getMainDomainInfo } from 'utils/site';

const PREFIX = 'ReadyToGoLiveCard';

const classes = {
  iconReady: `${PREFIX}IconReady`,
  gridReady: `${PREFIX}GridReady`,
  cardContentReady: `${PREFIX}CardContentReady`,
  cardContentOpen: `${PREFIX}CardContentOpen`,
  boxController: `${PREFIX}BoxController`,
  boxStep: `${PREFIX}BoxStep`,
  boxNote: `${PREFIX}BoxNote`,
  table: `${PREFIX}Table`,
  formControlLabel: `${PREFIX}FormControlLabel`,
  typographyLabel: `${PREFIX}TypographyLabel`,
  typographyDescription: `${PREFIX}TypographyDescription`,
  typographyTitle: `${PREFIX}TypographyTitle`,
};
const DNS_PROVIDER_CLOUDFLARE: string = 'cloudflare';

const StyledCard = styled(Card)({
  [`& .${classes.iconReady}`]: {
    height: '2.8125rem',
    left: '1rem',
    position: 'absolute',
    top: '1.25rem',
    width: 'auto',
    '@media (min-width: 60rem)': {
      left: '1.25rem',
    },
    '& + *': {
      paddingLeft: '3.5rem',
      '@media (min-width: 60rem)': {
        paddingLeft: '3.75rem',
      },
    },
  },
  [`& .${classes.gridReady}`]: {
    '& > *:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '2.8125rem',
      '& > *:first-of-type': {
        marginTop: 'auto',
      },
      '& > *:last-child': {
        marginBottom: 'auto',
      },
    },
    '& > *:last-child': {
      textAlign: 'left',
      '@media (min-width: 60rem)': {
        textAlign: 'right',
      },
    },
  },
  [`& .${classes.cardContentReady}`]: {
    position: 'relative',
  },
  [`& .${classes.cardContentOpen}`]: {
    backgroundColor: cssVar('--color-ebb'),
    borderRadius: '0 0 var(--border-radius) var(--border-radius)',
    boxShadow: 'inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05)',
    '@media (min-width: 60rem)': {
      padding: '1.25rem',
    },
    '&:last-child': {
      '@media (min-width: 60rem)': {
        paddingBottom: '1.25rem',
      },
    },
  },
  [`& .${classes.boxController}`]: {
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: '1.25rem',
    },
  },
  [`& .${classes.boxStep}`]: {
    display: 'flex',
    '&:not(:first-of-type)': {
      marginTop: '1.25rem',
    },
    '&:not(:last-child)': {
      marginBottom: '1.25rem',
    },
    '& > *:first-of-type': {
      flex: '0 0 auto',
      marginRight: '1rem',
      minWidth: '0',
    },
    '& > *:last-child': {
      flex: '1 1 auto',
      minWidth: '0',
    },
    '& button': {
      margin: '0 0.1875rem',
      top: '-0.0625rem',
    },
  },
  [`& .${classes.boxNote}`]: {
    marginTop: '2.5rem',
    '& > *:first-of-type': {
      marginBottom: '0.3125rem',
      textTransform: 'uppercase',
    },
    '& button': {
      margin: '0 0.1875rem',
      top: '-0.0625rem',
    },
  },
  [`& .${classes.table}`]: {
    '& strong': {
      marginRight: '0.3125rem',
    },
  },
  [`& .${classes.formControlLabel}`]: {
    alignItems: 'flex-start',
    display: 'flex',
    marginLeft: '0',
    marginRight: '0',
    '& > *:first-of-type': {
      margin: '0.0625rem 0.3125rem 0 0',
    },
  },
  [`& .${classes.typographyLabel}`]: {
    marginBottom: '0.3125rem',
    '& button': {
      margin: '-0.4375rem 0.5rem -0.4375rem 0',
    },
  },
  [`& .${classes.typographyDescription}`]: {
    margin: '0 0 auto 0 !important',
  },
  [`& .${classes.typographyTitle}`]: {
    '&:not(:first-of-type)': {
      marginTop: '1.25rem',
    },
    marginBottom: '0.3125rem',
  },
});

interface SetupInstructionsProps {
  readonly instructions: {
    key: string;
    data: any;
  }[];
  testId: string;
}

function SetupInstructions({ instructions, testId }: SetupInstructionsProps) {
  const { t } = useTranslation();

  return instructions.map(({ key, data }, index) => (
    <Box className={classes.boxStep} data-testid={`${testId}-${index + 1}`} key={data}>
      <Chip label={t('step_number', { number: index + 1 })} />
      <Typography>
        <Trans i18nKey={key} values={data} components={[<strong key="strong" />]} />
      </Typography>
    </Box>
  ));
}

interface DNSRecordsTableProps {
  readonly records: {
    type: string;
    name: string;
    value: string;
  }[];
  readonly label: string;
  readonly hideTTLColumn?: boolean;
}

function DNSRecordsTable({ records, label, hideTTLColumn = false }: DNSRecordsTableProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography>{t('example_how_it_make_look')}</Typography>
      <TableContainer>
        <Table aria-label={label} className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('type')}</TableCell>
              <TableCell>{t('name')}</TableCell>
              <TableCell>{t('value')}</TableCell>
              {!hideTTLColumn && <TableCell>{t('ttl')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map(row => (
              <TableRow key={row.type + row.name}>
                <TableCell>{row.type.toUpperCase()}</TableCell>
                <TableCell>
                  <strong>{row.name}</strong>
                  <CopyButton value={row.name} />
                </TableCell>
                <TableCell>
                  <strong>{row.value}</strong>
                  <CopyButton value={row.value} />
                </TableCell>
                {!hideTTLColumn && <TableCell>{t('automatic')}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

interface StepProps {
  readonly siteDetails?: SiteDetail;
  readonly setActiveStep: (step: number) => unknown;
}

function UpdateDns({ siteDetails }: Pick<StepProps, 'siteDetails'>) {
  const { t } = useTranslation();

  const hardcodedIP = '104.19.154.92';
  const siteId = siteDetails?.id?.toString() ?? '';
  const updateGoLiveMode = useUpdateGoLiveMode(siteId);
  const forceValidationRecheck = useForceValidationRecheck(siteId, true);
  const rocketUrl = siteDetails?.rocket_url ?? '';

  const { domainType, baseDomain, subdomain, dnsProvider } = getMainDomainInfo(siteDetails);

  let instructions: SetupInstructionsProps['instructions'];
  let dnsRecords: DNSRecordsTableProps['records'];

  if (domainType === 'subdomain') {
    instructions = [
      { key: 'update_dns_instructions_part_three_subdomain', data: { subdomain } },
      { key: 'update_dns_instructions_part_four', data: { value: rocketUrl } },
    ];

    dnsRecords = [{ type: 'CNAME', name: subdomain, value: rocketUrl }];
  } else {
    // Apex will have IP (non-cloudflare DNS) or rocket url (cloudflare DNS)
    const apexValue: string = dnsProvider !== DNS_PROVIDER_CLOUDFLARE ? hardcodedIP : rocketUrl;
    const apexType: string = dnsProvider !== DNS_PROVIDER_CLOUDFLARE ? 'A' : 'CNAME';

    instructions = [
      { key: 'update_dns_instructions_part_three_domain', data: { type: apexType, baseDomain } },
      { key: 'update_dns_instructions_part_four', data: { value: apexValue } },
      { key: 'update_dns_instructions_part_five_domain', data: { baseDomain } },
    ];

    dnsRecords = [
      { type: apexType, name: '@', value: apexValue },
      { type: 'CNAME', name: 'www', value: rocketUrl },
    ];
  }

  return (
    <StyledCard>
      <CardHeader title="Update DNS" />
      <CardContent>
        <Typography variant="h3" className={classes.typographyTitle}>
          {t('update_dns')}
        </Typography>
        <Typography>{t('update_dns_instructions_part_one')}</Typography>

        <Typography variant="h3" className={classes.typographyTitle}>
          {t('setting_up')}
        </Typography>
        <Typography>{t('update_dns_instructions_part_two')}</Typography>

        <SetupInstructions instructions={instructions} testId="update-dns-step" />
        <DNSRecordsTable records={dnsRecords} label="Update DNS Example Table" />

        <Box className={classes.boxController}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked
                onChange={async () => {
                  await updateGoLiveMode.mutateAsync({ hide_go_live: 1 });
                }}
                name="dontShowGoLive"
              />
            }
            label={t('dont_display_reminder_anymore')}
            className={classes.formControlLabel}
          />
        </Box>
        <ProgressiveButton
          text={t('ive_update_my_dns')}
          onClick={async () => {
            try {
              await forceValidationRecheck.mutateAsync();
            } catch (e) {
              // expect error if domain already in use
            }
            await updateGoLiveMode.mutateAsync({ hide_go_live: 1 });
          }}
          isLoading={updateGoLiveMode.isPending}
        />
      </CardContent>
    </StyledCard>
  );
}

function ConfigureSSL({ siteDetails, setActiveStep }: StepProps) {
  const siteId = siteDetails?.id?.toString() ?? '';
  const [showForceRecheckToaster, setShowForceRecheckToaster] = React.useState<boolean>(false);
  const updateSSLValidation = useUpdateSSLValidation(siteId);
  const forceValidationRecheck = useForceValidationRecheck(siteId);

  const { baseDomain, subdomain, validationRecords, sslStatus } = getMainDomainInfo(siteDetails);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (sslStatus === 'active') {
      setActiveStep(3);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    const messages: string[] = forceValidationRecheck.data?.data?.messages ?? [];

    if (messages.includes('Domain is already active')) {
      setActiveStep(3);
    }

    if (forceValidationRecheck.status === 'success') {
      setShowForceRecheckToaster(true);
    }
  }, [siteDetails, forceValidationRecheck.status, setShowForceRecheckToaster, setActiveStep]);

  const records = validationRecords.map((record: VerificationRecord) => ({
    ...record,
    name:
      baseDomain === record.name ? (subdomain ?? '@') : record.name.replace(`.${baseDomain}`, ''),
  }));

  const instructions = records.map(record => ({
    key: 'ready_to_go_live_verification',
    data: { type: record.type, name: record.name, value: record.value },
  }));

  return (
    <Card>
      <CardHeader title={t('configure_ssl')} />
      <CardContent>
        <Snackbar
          TransitionProps={{
            appear: false,
            exit: false,
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showForceRecheckToaster}
          onClose={() => setShowForceRecheckToaster(false)}
        >
          <Alert
            variant="filled"
            onClose={() => setShowForceRecheckToaster(false)}
            severity="warning"
          >
            <Typography>{t('status_pending_validation')}</Typography>
            <Typography>{t('please_wait_or_check_status_later')}</Typography>
          </Alert>
        </Snackbar>
        <Typography variant="h3" className={classes.typographyTitle}>
          {t('configure_free_ssl')}
        </Typography>
        <Typography>{t('configure_free_ssl_description')}</Typography>
        <SetupInstructions instructions={instructions} testId="configure-ssl-step" />
        <DNSRecordsTable records={records} hideTTLColumn label="SSL Records Table" />
        <ProgressiveButton
          text={t('ive_added_txt_records_continue')}
          onClick={async () => {
            await forceValidationRecheck.mutateAsync();
          }}
          isLoading={forceValidationRecheck.isPending}
        />
        <Box className={classes.boxNote}>
          <Chip label={t('note_on_this_step')} />
          <Typography>
            <Trans
              i18nKey="self_signed_certificate_step_1"
              components={[
                <Button
                  key="1"
                  color="primary"
                  onClick={async () => {
                    await updateSSLValidation.mutateAsync({
                      validation_method: 'http',
                    });
                    setActiveStep(3);
                  }}
                />,
              ]}
            />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

function ChangeDomainName({ siteDetails, setActiveStep }: StepProps) {
  interface DomainFormValue {
    domain: string;
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const methods = useForm<DomainFormValue>({
    defaultValues: {
      domain: '',
    },
    mode: 'onChange',
  });
  const { handleSubmit, formState } = methods;

  const setMainDomain = useSetMainDomain(siteDetails?.id?.toString() ?? '');

  const { t } = useTranslation();

  const onSubmit = async (data: DomainFormValue) => {
    await setMainDomain.mutateAsync({
      domain: `${data.domain.toLowerCase()}`,
    });
    setActiveStep(2);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Card>
      <CardHeader title="Change Domain Name" />
      <CardContent>
        <Typography className={classes.typographyLabel}>
          {t('enter_your_domain_name')}
          <IconButton
            aria-describedby="productionVsStaging"
            onClick={handleClick}
            color="primary"
            size="large"
          >
            <HelpOutlineIcon />
          </IconButton>
          <Popover
            id="productionVsStaging"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography variant="h3">{t('enter_your_domain_name_information_title')}</Typography>
            <Typography>{t('enter_your_domain_name_information_description')}</Typography>
          </Popover>
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              defaultValue=""
              name="domain"
              disabled={setMainDomain.isPending}
              placeholder={String(t('enter_your_domain_name_placeholder'))}
              fullWidth
              rules={{
                required: true,
                validate: domain => {
                  return isFQDN(domain);
                },
              }}
              formatValue={val => {
                if (val.includes('https://www.')) {
                  return val.replace('https://www.', '');
                } else if (val.includes('http://www.')) {
                  return val.replace('http://www.', '');
                } else if (val.includes('https://')) {
                  return val.replace('https://', '');
                } else if (val.includes('http://')) {
                  return val.replace('http://', '');
                }
                return val;
              }}
              startAdornment={
                <InputAdornment
                  position="start"
                  disableTypography
                  component="button"
                  variant="outlined"
                >
                  http(s)://
                </InputAdornment>
              }
            />
            <Box marginTop={2}>
              <ProgressiveButton
                text={t('continue')}
                disabled={setMainDomain.isPending || !formState.isValid}
                onClick={async () => {
                  await handleSubmit(onSubmit)();
                }}
                isLoading={setMainDomain.isPending}
              />
            </Box>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
}

function ReadyToGoLiveContent({ siteDetails }: { readonly siteDetails: SiteDetail }) {
  const { t } = useTranslation();

  const { sslStatus, dnsProvider, hostnameStatus, validationMethod } =
    getMainDomainInfo(siteDetails);

  let initialActiveStep = 1;

  if (validationMethod === 'http' || (sslStatus === 'active' && hostnameStatus === 'active')) {
    initialActiveStep = 3;
  }

  if ((sslStatus && sslStatus !== 'active') || (hostnameStatus && hostnameStatus !== 'active')) {
    initialActiveStep = 2;
  }

  if (dnsProvider === DNS_PROVIDER_CLOUDFLARE && sslStatus === 'active') {
    initialActiveStep = 3;
  }

  const [activeStep, setActiveStep] = React.useState<number>(initialActiveStep);

  const StepComponent = [ChangeDomainName, ConfigureSSL, UpdateDns][activeStep - 1];

  return (
    <CardContent className={classes.cardContentOpen}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <WizardStep
            activeStep={activeStep}
            step={1}
            title={t('ready_to_go_live_wizard_step_one_title')}
            description={t('ready_to_go_live_wizard_step_one_description')}
          />
          <WizardStep
            activeStep={activeStep}
            step={2}
            title={t('ready_to_go_live_wizard_step_two_title')}
            description={t('ready_to_go_live_wizard_step_two_description')}
          />
          <WizardStep
            activeStep={activeStep}
            step={3}
            title={t('ready_to_go_live_wizard_step_three_title')}
            description={t('ready_to_go_live_wizard_step_three_description')}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <StepComponent siteDetails={siteDetails} setActiveStep={setActiveStep} />
        </Grid>
      </Grid>
    </CardContent>
  );
}

export function ReadyToGoLiveCard({ siteDetails }: { readonly siteDetails?: SiteDetail }) {
  const { sslStatus, showGoLive } = getMainDomainInfo(siteDetails);

  const [open, setOpen] = React.useState<boolean>(!!sslStatus);

  const { t } = useTranslation();

  if (!siteDetails || (siteDetails && siteDetails.production) || showGoLive === 0) {
    return null;
  }

  const openText = sslStatus ? 'Open' : 'Get Started';

  return (
    <StyledCard>
      <CardContent className={classes.cardContentReady}>
        <ReadyToGoLiveIcon className={classes.iconReady} />
        <Grid container spacing={2} alignItems="center" className={classes.gridReady}>
          <Grid item xs={12} md={8}>
            <Typography variant="h2">{t('ready_to_go_live')}</Typography>
            <Typography className={classes.typographyDescription}>
              {t('ready_to_go_live_description')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              color="primary"
              onClick={() => setOpen(!open)}
              variant={open ? 'outlined' : 'contained'}
            >
              {open ? t('close') : openText}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      {open ? <ReadyToGoLiveContent siteDetails={siteDetails} /> : null}
    </StyledCard>
  );
}
