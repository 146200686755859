import React from 'react';
import { Button, InputAdornment } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { CreateFtpAccountData, useCreateFtpAccount } from 'api/ftpAccounts';
import { useParams } from 'react-router-dom';
import { TextField } from 'component/base/TextField';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';
import passwordStrength from 'password-strength-calc';

interface Props {
  readonly domain: string;
  readonly refetchFtpAccounts: () => Promise<unknown>;
}

export function AddFtpAccount({ domain, refetchFtpAccounts }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false);
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { mutateAsync, isPending } = useCreateFtpAccount({
    id: siteId,
  });
  const strippedDomain = domain.replace('www.', '');
  const methods = useForm<CreateFtpAccountData>({
    defaultValues: {
      domain: strippedDomain,
      username: '',
      password: '',
      homedir: '',
      quota: 0,
    },
  });
  const { handleSubmit, reset } = methods;
  const { t } = useTranslation();
  const onSubmit = async (data: CreateFtpAccountData) => {
    if (passwordStrength(data.password) < 60) {
      enqueueSnackbar(t('ftp_account_password_error'), {
        key: 'passwordStrengthError',
        variant: 'error',
      });
      return;
    }

    await mutateAsync({
      ...data,
      homedir: data.homedir ? `public_html/${data.homedir}` : 'public_html',
    });
    await refetchFtpAccounts();
    reset();
    setShowAddDialog(false);
  };

  function handleClose() {
    reset();
    setShowAddDialog(false);
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setShowAddDialog(true);
        }}
      >
        {t('add_ftp_account')}
      </Button>
      <ConfirmationDialog
        action="confirm"
        description={
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                defaultValue=""
                name="username"
                label={t('username')}
                disabled={isPending}
                fullWidth
                endAdornment={
                  <InputAdornment
                    position="end"
                    disableTypography
                  >{`@${strippedDomain}`}</InputAdornment>
                }
                rules={{ required: true }}
              />
              <TextField
                defaultValue=""
                name="password"
                disabled={isPending}
                fullWidth
                label={t('password')}
                type="password"
                rules={{ required: true }}
              />
              <TextField
                defaultValue=""
                name="homedir"
                disabled={isPending}
                fullWidth
                label={t('directory')}
                startAdornment={
                  <InputAdornment position="start" disableTypography>
                    {'public_html/'}
                  </InputAdornment>
                }
              />
            </form>
          </FormProvider>
        }
        id="createFtpAccountModal"
        onConfirm={handleSubmit(onSubmit)}
        onClose={handleClose}
        open={showAddDialog}
        title={t('create_ftp_account')}
        confirmText={String(t('create'))}
      />
    </>
  );
}
