import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, PaginatedApiResponse, SiteDetailsData } from './types';
import { AxiosService } from 'api/axiosService';

export interface FtpAccount {
  dir: string;
  diskquota: string;
  diskused: string;
  reldir: string;
  user: string;
}

export interface FtpAccountsPaginationState {
  siteId: string;
  perPage: number;
  activePageNumber: number;
  filter: string;
  sortAttr: string;
  sortOrder: 'asc' | 'desc';
}

export const useFtpAccounts = ({
  siteId,
  perPage,
  activePageNumber,
  filter,
  sortAttr,
  sortOrder,
}: FtpAccountsPaginationState) => {
  return useQuery({
    queryKey: ['ftpAccounts'],

    queryFn: async () =>
      await AxiosService.get<PaginatedApiResponse<FtpAccount[]>>(
        `sites/${siteId}/ftp/accounts?username=${filter}&page=${activePageNumber}&per_page=${perPage}&sort=${sortAttr}&direction=${sortOrder}`
      ),

    retry: 0,
    gcTime: 0,
  });
};

export interface CreateFtpAccountData {
  domain: string;
  username: string;
  password: string;
  homedir: string;
  quota: number;
}

export const useCreateFtpAccount = (data: SiteDetailsData) => {
  return useMutation({
    mutationKey: ['createFtpAccount'],

    mutationFn: async (body: CreateFtpAccountData) =>
      await AxiosService.post<ApiResponse<FtpAccount>>(`sites/${data.id}/ftp/accounts`, body),
  });
};

export interface UpdateFtpAccountData {
  new_password?: string;
  homedir?: string;
}

export const useUpdateFtpAccount = (data: SiteDetailsData & { username: string }) => {
  return useMutation({
    mutationKey: ['updateFtpAccount'],

    mutationFn: async (body: UpdateFtpAccountData) =>
      await AxiosService.patch<ApiResponse<FtpAccount[]>>(
        `sites/${data.id}/ftp/accounts?username=${data.username}`,
        body
      ),
  });
};

interface DeleteFtpAccountData {
  username: string;
}

export const useDeleteFtpAccount = (data: SiteDetailsData) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['deleteFtpAccount'],

    mutationFn: async (body: DeleteFtpAccountData) =>
      await AxiosService.delete<ApiResponse<any>>(`sites/${data.id}/ftp/accounts`, {
        data: body,
      }),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['ftpAccounts'],
      }),
  });
};
